import { ReactComponent as Hello } from './svg/hello.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <div className="svg-container">
        <Hello />
      </div>
    </div>
  );
}

export default App;
